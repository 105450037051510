import { Container } from "@material-ui/core";
import { css } from "linaria";
import { styled } from "linaria/react";
import React from "react";
import { ContentRenderer } from "../../Shared/ContentRenderer/ContentRenderer";
import { Heading } from "../../Shared/Heading/Heading";
import { Section } from "../../Shared/Section/Section";
import { muiTheme } from "../../Shared/Theme/Theme";

export interface SingleCarContentProps {
  className?: string;
  content: string;
}

const headingStyles = css`
  margin-bottom: 6rem;
`;

const SingleCarContentInner = (props: SingleCarContentProps) => {
  const { content } = props;
  return (
    <Section className={props.className}>
      <Container>
        <header>
          <Heading className={headingStyles} heading="Podrobný popis" />
        </header>
        <ContentRenderer source={content} />
      </Container>
    </Section>
  );
};

export const SingleCarContent = styled(SingleCarContentInner)`
  margin-top: 6rem;

  ${muiTheme.breakpoints.down('sm')} {
    margin-top: 0rem;
  }
`;
