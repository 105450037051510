import { graphql } from "gatsby";
import React from "react";
import Layout from "../../components/Shared/Layout/Layout";
import { SEO } from "../../components/Shared/SEO/SEO";
import { SingleCarContent } from "../../components/SingleCar/SingleCarContent/SingleCarContent";
import { SingleCarGalleries } from "../../components/SingleCar/SingleCarGalleries/SingleCarGalleries";
import { SingleCarGallery } from "../../components/SingleCar/SingleCarGallery/SingleCarGallery";
import { SingleCarHero } from "../../components/SingleCar/SingleCarHero/SingleCarHero";

export const query = graphql`
  query($slug: String) {
    strapiCar(slug: { eq: $slug }) {
      shortDescription
      name
      longDescription
      slug
      galleries: NamedGallery {
        name
        gallery {
          image {
            publicURL
            childImageSharp {
              fluid(maxWidth: 500, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
      image {
        publicURL
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  }
`;

const IndexPage = ({ data: { strapiCar } }: any) => {
  return (
    <Layout>
      <SEO
        title={strapiCar.name}
        description={strapiCar.shortDescription}
        image={`https://colderia.sk${strapiCar.image.publicURL}`}
      />
      <SingleCarHero {...strapiCar} />
      <SingleCarGalleries galleries={strapiCar.galleries} />
      <SingleCarContent content={strapiCar.longDescription} />
    </Layout>
  );
};

export default IndexPage;
