import { Container, Grid } from "@material-ui/core";
import GatsbyImage, { FluidObject } from "gatsby-image";
import { css } from "linaria";
import { styled } from "linaria/react";
import React from "react";
import { Hero } from "../../Home/Hero/Hero";
import DotPattern from "../../Shared/misc/DotPattern/DotPattern";
import { muiTheme, theme } from "../../Shared/Theme/Theme";

export interface SingleCarHeroProps {
  className?: string;
  name: string;
  image: {
    childImageSharp: {
      fluid: FluidObject;
    };
  };
  shortDescription: string;
}

const SingleCarHeading = styled.h1`
  font-weight: 600;
  font-size: 6.2rem;
  position: relative;
  display: inline-flex;

  &::after {
    height: 6px;
    width: 30%;
    content: "";
    background-color: ${theme.color.primary};
    position: absolute;
    bottom: -10px;
    left: 0;
  }
`;

const dotPatternStyles = css`
  position: absolute;
  top: 0;
  right: 40px;
  z-index: -1;
  transform: scale(1.5);

  ${muiTheme.breakpoints.down("xs")} {
    transform: scale(1.2);
    right: 0px;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;

  ${muiTheme.breakpoints.down("sm")} {
    margin-top: 6rem;
  }
`;

const SingleCarShortDescription = styled.p`
  font-weight: 300;
  font-size: 1.8rem;
  margin-top: 5rem;
`;

const HeroInfo = styled.div`
  width: 80%;

  ${muiTheme.breakpoints.down("xs")} {
    width: 100%;
  }
`;

const SingleCarHeroInner = (props: SingleCarHeroProps) => {
  const { name, image, shortDescription } = props;

  return (
    <div className={props.className}>
      <Container>
        <Grid container>
          <Grid item xs={12} md={6}>
            <HeroInfo>
              <SingleCarHeading>{name}</SingleCarHeading>
              <SingleCarShortDescription>
                {shortDescription}
              </SingleCarShortDescription>
            </HeroInfo>
          </Grid>
          <Grid item xs={12} md={6} container alignItems="center">
            <ImageWrapper>
              <GatsbyImage fluid={image.childImageSharp.fluid} />
              <DotPattern className={dotPatternStyles} />
            </ImageWrapper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export const SingleCarHero = styled(SingleCarHeroInner)`
  margin-top: 8rem;

  ${muiTheme.breakpoints.down("sm")} {
    margin-top: 0rem;
  }
`;
