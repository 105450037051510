import { Container, useMediaQuery } from "@material-ui/core";
import { Link } from "gatsby";
import { css } from "linaria";
import { styled } from "linaria/react";
import React from "react";
import { routes } from "../../../lib/routes";
import { GalleryPhoto } from "../../Home/Gallery/hooks/useHomeGalleryPhotos";
import { CDButton } from "../../Shared/Button/CDButton";
import { Heading } from "../../Shared/Heading/Heading";
import { Section } from "../../Shared/Section/Section";
import { muiTheme } from "../../Shared/Theme/Theme";
import { UniversalGallery } from "../../Shared/UniversalGallery/UniversalGallery";

export interface SingleCarGalleryProps {
  className?: string;
  images: GalleryPhoto[];
  heading?: string;
}


const headingStyles = css`
  .heading {
    &::after {
      right: 0;
      left: 0;
      margin: auto;
    }
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 6rem;
`;

const Header = styled.header`
  display: flex;
  justify-content: center;
`;

const SingleCarGalleryInner = (props: SingleCarGalleryProps) => {
  const { images = [], heading = "Výsledok našej práce" } = props;
  const matches = useMediaQuery(muiTheme.breakpoints.down("sm"));

  return (
    <Section className={props.className}>
      <Container>
        <Header>
          <Heading
            className={headingStyles}
            heading={heading}
            pattern={!matches}
          />
        </Header>
        <UniversalGallery key={heading} galleryKey={heading} images={images} />
        <Actions>
          <Link to={routes.contact.to}>
            <CDButton hovered>Máte záujem ?</CDButton>
          </Link>
        </Actions>
      </Container>
    </Section>
  );
};

export const SingleCarGallery = styled(SingleCarGalleryInner)`
`;
