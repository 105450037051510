import { styled } from "linaria/react";
import React from "react";
import { muiTheme } from "../../Shared/Theme/Theme";
import { SingleCarGallery } from "../SingleCarGallery/SingleCarGallery";

export interface SingleCarGalleriesProps {
  className?: string;
  galleries: any[];
}

const SingleCarGalleriesInner = (props: SingleCarGalleriesProps) => {
  const { galleries } = props;

  return (
    <div className={props.className}>
      {galleries.map((namedGallery) => (
        <SingleCarGallery
          key={namedGallery.name}
          heading={namedGallery.name}
          images={namedGallery.gallery.map((image: any) => image.image)}
        />
      ))}
    </div>
  );
};

export const SingleCarGalleries = styled(SingleCarGalleriesInner)`
  margin-top: 22rem;

  ${muiTheme.breakpoints.down("sm")} {
    margin-top: 8rem;
  }
`;
